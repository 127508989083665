import React from 'react'

import styled from '@emotion/styled'
import { language } from '@/helpers/Language'

const Capsule = styled.div`
  display: inline-flex;

  align-items: center;

  padding: 4px 6px;
  border-radius: 50px;
  border: 1px solid #002e4e;

  max-width: fit-content;
  max-height: fit-content;
  white-space: nowrap;

  color: #002e4e;
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: 0.48px;
  text-transform: uppercase;
`

export default function OriginalLang({ lang }) {
  return language().toLowerCase() !== lang?.toLowerCase() ? (
    <Capsule>{lang || 'n/a'}</Capsule>
  ) : null
}
