import React from 'react'
import styled from '@emotion/styled'

import CaretRightIcon from '@/assets/images/caret-right.svg'
import StyledLink from '../styled/Link'

export interface BreadcrumbItem {
  path: string
  label: string
  isDisabled: boolean
}

const Wrapper = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  justify-content: left;
  gap: 8px;
`

const Item = styled.div`
  max-width: 440px;
  width: fit-content;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
  -webhkit-box-align: center;

  align-items; center;

  overflow: hidden;
  color: #00121F;
  text-overflow: ellipsis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.32px;

  &.disabled {
    color: #cacbcf;
  }
`

const Separator = styled.img``

export default function Breadcrumb({
  items = []
}: {
  items: BreadcrumbItem[]
}) {
  return (
    <Wrapper>
      {items.map(({ path, label, isDisabled }, index) => {
        let component = null

        if (items.length > 1 && index > 0) {
          component = (
            <Item style={{ height: '16px' }}>
              <Separator src={CaretRightIcon} width={16} height={16} />
            </Item>
          )
        }

        const breadcrumbItem =
          component + isDisabled ? (
            <Item className="disabled" key={path}>
              {label}
            </Item>
          ) : (
            <StyledLink to={path}>
              <Item key={path}>{label}</Item>
            </StyledLink>
          )

        return (
          <React.Fragment key={path}>
            {component}
            {breadcrumbItem}
          </React.Fragment>
        )
      })}
    </Wrapper>
  )
}
