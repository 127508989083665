import React from 'react'

import { FacebookShareButton, TwitterShareButton } from 'react-share'

import FacebookLogoImg from '@/assets/images/facebook.png'
import XLogoImg from '@/assets/images/x.png'
import LinkedinLogoImg from '@/assets/images/linkedin.png'
import CopyLinkLogoImg from '@/assets/images/link-copy.png'
import styled from '@emotion/styled'

import { useLocation } from '@reach/router'
import { media } from '@/styles/media'

const ShareWrapper = styled.div`
  display: flex;

  flex-direction: row;
  align-items: center;
  gap: 16px;

  img,
  .react-share__ShareButton {
    width: 32px;
    height: 32px;
  }

  ${media.tabletLandscapeDown} {
    gap: 12px;

    img,
    .react-share__ShareButton {
      width: 40px;
      height: 40px;
    }
  }
`

const SocialMediaAccount = styled.img``

export default function Share() {
  const location = useLocation()

  const openPopup = (name: string, url: string) => {
    window.open(
      url, // The URL to open
      name, // The name of the popup window (optional)
      'width=600,height=400,scrollbars=yes,resizable=yes' // Window features
    )
  }

  return (
    <ShareWrapper>
      <FacebookShareButton url={location.href}>
        <SocialMediaAccount src={FacebookLogoImg} />
      </FacebookShareButton>

      <TwitterShareButton url={location.href}>
        <SocialMediaAccount src={XLogoImg} />
      </TwitterShareButton>

      <button
        type="button"
        style={{
          border: 'none',
          background: 'none',
          margin: 0,
          padding: 0,
          lineHeight: 0
        }}
        onClick={() => {
          openPopup(
            'LinkedIn',
            `https://www.linkedin.com/sharing/share-offsite/?url=${location.href}`
          )
        }}
      >
        <SocialMediaAccount src={LinkedinLogoImg} />
      </button>

      <button
        type="button"
        style={{
          border: 'none',
          background: 'none',
          margin: 0,
          padding: 0,
          lineHeight: 0
        }}
        onClick={() => {
          navigator.clipboard.writeText(location.href)
        }}
      >
        <SocialMediaAccount src={CopyLinkLogoImg} />
      </button>
    </ShareWrapper>
  )
}
